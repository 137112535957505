@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #282c34;
  --secondary-color: #3e4452;
  --tertiary-color: #61dafb;
  --divider-size: 2px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}


.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}


.divider {
  background-color: var(--tertiary-color);
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;

  &.vertical {
    height: 100%;
    width: var(--divider-size);
  }

  &.horizontal {
    height: var(--divider-size);
    width: 100%;
  }
}

.header {
  height: 8%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
}

.header-date {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: calc( min( 1.5vw, 2vh ) );
  background-color: var(--secondary-color);
  font-weight: 400;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.header-title {
  flex-shrink: 0;
  flex-grow: 1;
  font-size: calc( min( 3vw, 7vh ) );
}

.header-nr {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  font-size: calc( min( 1.5vw, 4vh ) );
  font-weight: 400;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.content {
  height: calc(92% - var(--divider-size));
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-color);
  color: white;
}

.content-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - var(--divider-size)/2);
  height: 100%;
}

.content-header {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc( min( 1.5vw, 4vh ) );
}


.pie-chart-container {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 5%; */
}

.bar-chart-container {
  /* height: 80%; */
  /* min-height: 60%; */
  /* height: 100%; */
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stopped-skipped {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* height: 10%; */
  background-color: var(--primary-color);
  color: white;
  font-size: calc( min( 1vw, 2vh ) );
}

.stopped-skipped-header {
  width: 50%;
  min-width: 40%;
  flex-shrink: 1;
  flex-grow: 0;
  text-align: center;
  align-items: center;
  font-size: calc( min( 1.5vw, 4vh ) );
}

.stopped-skipped-list {
  flex-grow: 1;
  width: 100%;
  text-align: left;
}

.update {
  display: flex;
  flex-direction: row;
  justify-content: center;
  > * {
    margin: 0 5px;
    font-size: calc(min(2vw, 2vh));
  }
}

.header-title.mobile {
  display: none;
}

/* mobile */
@media (max-width: 768px) {


  .header-title.mobile {
    display: block;
  }

  .header-title.desktop {
    display: none;
  }


  .header-date {
    font-size: calc( min( 4vw, 4vh ) );
    padding: 0 10px;
  }

  .header-title {
    font-size: calc( min( 5vw, 10vh ) );
    padding: 0 10px;
  }

  .header-nr {
    font-size: calc( min( 4vw, 6vh ) );
    padding: 0 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 92%;
  }

  .content > * {
    width: 100%;
  }

  .divider.vertical {
    display: none;
  }

  .content-col.left {
    height: 50%;
  }

  .content-col.right {
    height: 50%;
  }

  .content-header {
    font-size: calc( min( 6vw, 6vh ) );
    margin-top: 10px;
  }

  .pie-chart-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 5%; */
  }

  .stopped-skipped {
    height: 10%;
  }

  .stopped-skipped-header {
    font-size: calc( min( 4vw, 6vh ) );
  }

  .stopped-skipped-list {
    font-size: calc( min( 3vw, 4vh ) );
    margin-left: 20px;
  }

  .update {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > * {
      margin: 0 5px;
      font-size: calc(min(3vw, 3vh));
    }
  }

}
